export const URLbase =
    //'https://beta.api.euresto.com/'
    //'http://192.168.0.77:5001/'
    'https://api.euresto.com/'
    //'http://192.168.0.77:5000/'
    //'http://api.euresto.local:5000/'
    //"https://api.euresto.com/"
    //"https://preprod.api.euresto.com/"
    //'http://127.0.0.1:5000/'
    ;
